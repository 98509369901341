export default function Reward() {
  return (
    <section className='reward-block' id='reward'>
      <div className='container'>
        {/* prizes */}
        <div className='prizes'>
          <div className='total'>
            <h3 className='section-title'>
              <span>รวมมูลค่ารางวัล</span>
              กว่า 350,000 บาท
            </h3>
          </div>
          <div className='list'>
            <div className='item'>
              <img src='/img/medal-1.png' alt='รางวัลชนะเลิศ' />
              <h4 className='section-sub-title'>รางวัลชนะเลิศ</h4>
              <p>เงินรางวัล 20,000 พร้อมประกาศนียบัตร และเหรียญรางวัล</p>
            </div>
            <div className='item'>
              <img src='/img/medal-2.png' alt='อันดับที่ 2' />
              <h4 className='section-sub-title'>อันดับที่ 2</h4>
              <p>เงินรางวัล 10,000 พร้อมประกาศนียบัตร และเหรียญรางวัล</p>
            </div>
            <div className='item'>
              <img src='/img/medal-3.png' alt='อันดับที่ 3' />
              <h4 className='section-sub-title'>อันดับที่ 3</h4>
              <p>เงินรางวัล 5,000 พร้อมประกาศนียบัตร และเหรียญรางวัล</p>
            </div>
          </div>
        </div>
        {/* Scholarship */}
        <div className='scholarship'>
          <div className='logo'>
            <img src='/img/gstm-logo.png' alt='GSTM NIDA' />
          </div>
          <div className='content'>
            <h3 className='section-sub-title'>ทีมที่ได้อันดับ 1 จะได้ทุนการศึกษาต่อทั้ง 3 คน</h3>
            <p>
              รางวัลพิเศษสำหรับน้องๆ ที่ได้รางวัลชนะเลิศ ทุกคนในทีมจะได้รับทุนการศึกษาต่อปริญญาโท
              คณะการจัดการการท่องเที่ยว สถาบันบัณฑิตพัฒนบริหารศาสตร์ (นิด้า)
              เลือกเรียนได้ทั้งภาคปกติ และภาคพิเศษ รวมมูลค่ากว่า 300,000 บาท!
            </p>
          </div>
        </div>
        {/* otop */}
        <div className='certificates'>
          <h4 className='section-sub-title'>ทุกทีมที่ผ่านการคัดเลือก</h4>
          <p>
            จะได้รับประกาศนียบัตร และสินค้าชุมชนติดไม้ติดมือกลับบ้าน รวมถึงมีโอกาสเข้าร่วมทำงาน
            กับผู้ประกอบการที่เป็นพันธมิตรกับโครงการ Young Scout Camp ต่อในอนาคต
          </p>
          <div className='otop-logo'></div>
        </div>
      </div>
    </section>
  )
}
