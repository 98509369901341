export default function Footer() {
  return (
    <footer className='footer'>
      <div className='container'>
        <div className='footer-wrapper'>
          <div className='left'>
            <img src='/img/logo/ysc-logo.png' alt='Footer Logo' className='footer-logo' />
            <p>โครงการยังสเกาท์แคมป์ ประเทศไทย</p>
          </div>
          <div className='right'>
            <p>
              ติดต่อสอบถาม ขอรายละเอียดร่วมเป็นผู้สนับสนุน
              <br />
              ได้ทาง youngscoutcamp.th@gmail.com
            </p>
            <ul className='social-list'>
              <li>
                <a
                  href='https://www.facebook.com/youngscoutcamp.th'
                  target='_blank'
                  title='Facebook page'
                  rel='noreferrer'>
                  <img src='/img/icon-facebook.png' alt='facebook' />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}
