export default function Intro() {
  return (
    <section className='intro-block'>
      <div className='container'>
        <div className='intro-wrapper'>
          {/* left */}
          <div className='left'>
            <div className='box egg large'>
              <h2>Young Scout Camp</h2>
              <p>
                งานแข่งขัน Hackathon ของเด็กชอบเที่ยว ใช้ไอเดียออกแบบเส้นทางตามโจทย์ที่กำหนด
                ในสภาพแวดล้อมที่จำกัด เพื่อกระตุ้นความคิดสร้างสรรค์ในการแก้ไขปัญหา
              </p>
              <p>รุ่น 1 จัดงานที่ กาญจนบุรี</p>
            </div>
            <div className='box light'>
              <div className='main-sponsor-wrapper'>
                <div className='logo'>
                  <img src='/img/ptg-logo.png' alt='PTG Energy' />
                </div>
                <div className='info'>
                  <h3>ผู้สนับสนุนรุ่นที่ 1</h3>
                  <p>เครือ PTG Energy</p>
                </div>
              </div>
            </div>
          </div>

          {/* center */}
          <div className='center'>
            <div className='box imagery'></div>
            <div className='box blue large'>
              <h4>รวมทีม 3 คน อายุไม่เกิน 25</h4>
              <h5>ที่มีใจรักพัฒนาสิ่งต่างๆ ให้ดีขึ้น</h5>
              <div className='team'></div>
            </div>
          </div>

          {/* right */}
          <div className='right'>
            <div className='box egg'>
              <p className='province'>กาญจนบุรี</p>
              <p className='meta'>
                <strong>2 วัน 1 คืน</strong>12 - 13 ธันวาคม 2566
              </p>
            </div>
            <div className='box imagery'></div>
          </div>
        </div>
      </div>
    </section>
  )
}
