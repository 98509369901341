export default function Qualification() {
  return (
    <section className='qualification-block'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-7 col-xl-8'>
            <h3 className='section-title'>คุณสมบัติผู้เข้าร่วม</h3>
            <ul>
              <li>อายุระหว่าง 18 - 25 ปี ไม่จำกัดเพศ</li>
              <li>รวมกลุ่ม 3 คน ถนัดใช้เทคโนโลยี สื่อโซเชียล มีความคิดสร้างสรรค์</li>
              <li>มีใจรักอยากพัฒนาสิ่งต่างๆให้ดีขึ้น</li>
              <li>เป็นคนชอบท่องเที่ยว โดยเฉพาะเที่ยวในประเทศ</li>
              <li>ถนัดการใช้เทคโนโลยี โซเชียลมีเดีย นำเสนอภาษาไทยได้</li>
              <li>
                ทุกคนในทีมต้องเข้าร่วมค่ายที่จังหวัดกาญจนบุรีได้ (2 วัน 1 คืน) นอนโรงแรม
                มีอาหารเช้า-เย็น มีรถบัสรับส่งกทม. ถึงสถานที่จัดงาน
              </li>
            </ul>
          </div>
          <div className='col-lg-5 col-xl-4'>
            <h3 className='section-title'>ความท้าทาย</h3>
            <ul>
              <li>
                มี pocket money ให้แต่ละทีม นำไปทำเส้นทางท่องเที่ยวของตัวเองในจังหวัดกาญจนบุรี
              </li>
              <li>ต้องทำกิจกรรมตามโจทย์ที่กำหนด และมีจับฉลากสุ่มรับโจทย์ย่อย</li>
              <li>แก้ไขปัญหาภายในเวลาที่กำหนด และทำงานภายใต้ข้อจำกัดที่ทีมตัวเองจะได้รับ</li>
            </ul>
          </div>
        </div>
        <div className='quote'>
          <p>เพื่อจำลองให้เห็นว่าธุรกิจท่องเที่ยวต้องมีการปรับตัว และเปลี่ยนแปลงตลอดเวลา</p>
        </div>
      </div>
    </section>
  )
}
