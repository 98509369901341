export default function Winner() {
  return (
    <section className='winner-section'>
      <div className='container'>
        <h3 className='section-title large text-center'>ผลการประกวดรอบสุดท้าย</h3>
        <div className='row'>
          <div className='col-md-4'>
            <div className='item'>
              <img src='/img/ysc-winner.jpg' alt='รางวัลชนะเลิศ' />
              <h4>รางวัลชนะเลิศ</h4>
              <p>ทีม ไปเที่ยวกาญญ กับโจทย์ ทริปสายมู - พระเกจิสังขารเหนือกาลเวลา</p>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='item'>
              <img src='/img/ysc-first-runner.jpg' alt='รองชนะเลิศอันดับ 1' />
              <h4>รองชนะเลิศอันดับ 1</h4>
              <p>ทีม สุขกาญเถอะเรา กับโจทย์ จุดห้ามพลาด ถ่ายรูปเช็คอิน วิวสวยสุดฮิต</p>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='item'>
              <img src='/img/ysc-second-runner.jpg' alt='รองชนะเลิศอันดับ 2' />
              <h4>รองชนะเลิศอันดับ 2</h4>
              <p>ทีม เพื่อนไม่ทิ้งกัน กับโจทย์ ทริปลงชุมชน จักสานบ้านท่าตะคร้อ</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
