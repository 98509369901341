import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Autoplay } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/pagination'

export default function Gallery() {
  const breakpoints = {
    320: {
      slidesPerView: 1,
      spaceBetween: 0,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 16,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 16,
    },
    1280: {
      slidesPerView: 4,
      spaceBetween: 24,
    },
  }

  return (
    <section className='gallery-block'>
      <Swiper
        modules={[Pagination, Autoplay]}
        spaceBetween={24}
        className='gallery-wrapper'
        pagination={{ clickable: true }}
        slidesPerView={4}
        breakpoints={breakpoints}
        autoplay={{ delay: 3000, disableOnInteraction: false }}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((index) => (
          <SwiperSlide className='slide-item' key={index}>
            <img src={`/img/gallery/ysc1-${index}.jpg`} alt={`Kanchanaburi ${index}`} />
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  )
}
