export default function Sponsor() {
  return (
    <>
      <section className='backed-by-block'>
        <div className='container'>
          <h3 className='section-title large'>ผู้สนับสนุนรุ่นที่ 1</h3>
          <div className='backed-by-wrapper'>
            <div className='main'>
              <img src='/img/ptg-logo.png' alt='PTG Energy' className='logo' />
              <div className='content'>
                <h4>Main Sponsor</h4>
                <p>PTG Energy</p>
              </div>
            </div>
            <div className='other'>
              <ul className='list'>
                <li>
                  <img src='/img/sponsors/tat-logo.png' alt='TAT' />
                </li>
                <li>
                  <img src='/img/sponsors/ttpa-logo.png' alt='TTPA' />
                </li>
                <li>
                  <img src='/img/sponsors/gstm.png' alt='GSTM NIDA' className='horizontal' />
                </li>
                <li>
                  <img src='/img/sponsors/tceb.png' alt='TCEB' />
                </li>
                <li>
                  <img src='/img/sponsors/lulla.png' alt='ลั้ลลา พาเที่ยว' />
                </li>
                <li>
                  <img src='/img/sponsors/westory.png' alt='Westory' />
                </li>
                <li>
                  <img src='/img/sponsors/patois-logo.png' alt='Patois' />
                </li>
                <li>
                  <img src='/img/sponsors/tripniceday-logo.png' alt='TripNiceDay' />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
