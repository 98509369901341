import Sticky from 'react-stickynode'

export default function Header() {
  const scrollToSection = (e: React.MouseEvent, sectionID: string) => {
    e?.preventDefault()
    const section = document.getElementById(sectionID)
    // scroll to section
    // section?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
    const offsetTop = section?.offsetTop
    window.scroll({ top: offsetTop, behavior: 'smooth' })
    // hide menu when click
    const menu = document.querySelector('.site-navigation-wrapper')
    menu?.classList.remove('active')
  }

  const openForm = (e: React.MouseEvent) => {
    e?.preventDefault()
    window.open('https://forms.gle/RrG9LqnHCW1v2D1P6')?.focus()
  }

  const toggleNavMenu = () => {
    const menu = document.querySelector('.site-navigation-wrapper')
    menu?.classList.toggle('active')
  }

  return (
    <Sticky enabled={window.innerWidth < 1200} innerZ={10} activeClass='sticked'>
      <header className='header'>
        <div className='container'>
          <div className='row'>
            <div className='col-4 col-sm-3'>
              <div className='site-logo-wrapper'>
                <a href='/' title='Young Scout Camp' className='site-logo'>
                  <img src='/img/logo/ysc-logo.png' alt='Young Scout Camp Thailand' />
                </a>
              </div>
            </div>
            <div className='col-8 col-sm-9'>
              <nav className='mobile-navigation'>
                <button
                  type='button'
                  className='menu-toggle'
                  id='mobile_toggle'
                  onClick={toggleNavMenu}>
                  <img src='/img/icon-menu.png' alt='เมนูนำทาง' />
                </button>
                <button type='button' className='button' onClick={openForm}>
                  สมัครเข้าร่วม
                </button>
              </nav>
              <nav className='site-navigation-wrapper'>
                <ul>
                  <li>
                    <button onClick={(e) => scrollToSection(e, 'about')}>เกี่ยวกับโครงการ</button>
                  </li>
                  <li>
                    <button onClick={(e) => scrollToSection(e, 'reward')}>เงินรางวัล</button>
                  </li>
                  <li>
                    <button onClick={(e) => scrollToSection(e, 'timeline')}>กำหนดการ</button>
                  </li>
                  <li>
                    <a
                      className='button'
                      href='https://forms.gle/RrG9LqnHCW1v2D1P6'
                      target='_blank'
                      onClick={openForm}
                      rel='noreferrer'>
                      สมัครเข้าร่วม
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </Sticky>
  )
}
