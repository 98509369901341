export default function Timeline() {
  return (
    <section className='timeline-block' id='timeline'>
      <div className='container'>
        <p className='section-title large'>กำหนดการรุ่นที่ 1</p>
        <img
          src='/img/timeline-mobile.jpg'
          alt='กำหนดการจัดงานรุ่นที่ 1'
          className='timeline-mobile'
        />
        <img src='/img/timeline.jpg' alt='กำหนดการจัดงานรุ่นที่ 1' className='timeline-large' />
      </div>
    </section>
  )
}
