export default function Why() {
  return (
    <section className='why-section-block' id='about'>
      <div className='container'>
        <h3 className='section-title large'>ทำไมน้องๆ ควรร่วมโครงการนี้</h3>
        <div className='why-content-wrapper'>
          <div className='imagery'>
            <img src='/img/icon-verify.png' alt='Community Builder' />
            <h3 className='section-title'>Community Builder</h3>
            <p>
              ทีมที่ผ่านการคัดเลือกจะเป็นเหมือนทีมงานประจำในแต่ละจังหวัด
              และได้ร่วมทำงานกับเครือข่ายผู้ประกอบการของ Young Scout Camp
            </p>
          </div>
          <div className='content'>
            <div className='row-item'>
              <div className='row-header'>
                <img src='/img/icon-route.png' alt='ต่อยอดผลงาน' />
                <h3 className='section-title'>ต่อยอดผลงาน</h3>
              </div>
              <p>
                เส้นทางที่น้องๆ สร้างขึ้นจากการเข้าร่วมโครงการ
                จะถูกนำไปใช้สร้างเส้นทางท่องเที่ยวให้คนทั่วไปได้เข้ามาร่วมเดินทางจริงผ่านแพลตฟอร์มของ
                TripNiceDay
              </p>
            </div>
            <div className='row-item'>
              <div className='row-header'>
                <img src='/img/icon-briefcase.png' alt='มีงานรองรับ' />
                <h3 className='section-title'>ได้ทำงานจริง</h3>
              </div>
              <p>
                นอกจากจะได้เก็บเป็นพอร์ตก่อนเข้าทำงานแล้ว เวลาจังหวัดมีจัดงาน
                หรือผู้ประกอบการต้องการคนช่วย โครงการจะทำหน้าที่ match งานกับคนให้ต่อไป
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
