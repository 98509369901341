export default function Join() {
  return (
    <>
      <section className='apply-block'>
        <div className='container'>
          <div className='apply-wrapper'>
            <div className='problems'>
              <div className='content'>
                <h3 className='section-title'>โจทย์รอบคัดเลือก</h3>
                <img src='/img/icon-location.png' alt='โจทย์รอบคัดเลือก' />
                <p>
                  ทำ presentation ขนาด A4* เล่าเกี่ยวกับที่เที่ยวหรือที่กินแบบ hidden gem หรือ
                  unseen ตามความคิดของตัวเอง ในจังหวัดกาญจนบุรี
                </p>
                <p>* ไฟล์ที่ส่งจะเป็นแนวตั้งหรือแนวนอนได้หมด ส่งไฟล์ในรูปแบบ pdf เท่านั้น</p>
              </div>
            </div>
            <div className='join'>
              <h3 className='section-title'>สมัครเข้าร่วมโครงการ</h3>
              <p>
                ตลอด 1 เดือนที่เปิดรับสมัคร มีผู้สนใจส่งผลงานเข้าประกวดทั้งหมด{' '}
                <strong>119 ทีม!</strong>
              </p>
              <p>โดยรู้จักโครงการ YSC จาก</p>
              <ul>
                <li>Facebook page: 55.1%</li>
                <li>อาจารย์ และมหาวิทยาลัย: 21%</li>
                <li>เพื่อน/คนรู้จักบอกต่อ: 18%</li>
                <li>อื่นๆ: 5.9%</li>
              </ul>
              <p>
                <small>
                  ติดตามข่าวสารการเปิดรับสมัครรุ่นถัดไป ได้ที่เพจ{' '}
                  <a
                    title='Young Scout Camp'
                    href='https://www.facebook.com/youngscoutcamp.th'
                    target='_blank'
                    rel='noreferrer'>
                    Young Scout Camp
                  </a>
                </small>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className='criteria-block'>
        <div className='container'>
          <h3 className='section-title large'>เกณฑ์รอบคัดเลือก</h3>
          <p>ถ้าคิดว่ารู้จักจังหวัดกาญจนบุรีดี ก็สมัครเข้ามาเลย!</p>
          <div className='list'>
            <div className='item'>
              <p className='percent'>40%</p>
              <p className='text'>
                ความน่าสนใจในการนำเสนอ
                <br />
                การเล่าเรื่อง
              </p>
            </div>
            <div className='item'>
              <p className='percent'>40%</p>
              <p className='text'>
                ความสดใหม่ของไอเดีย
                <br />
                และความคิดสร้างสรรค์
              </p>
            </div>
            <div className='item'>
              <p className='percent'>20%</p>
              <p className='text'>
                ความสวยงาม
                <br />
                ของชิ้นงานที่ส่ง
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
